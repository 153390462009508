import React from "react";
import heroSecBg from "../../assets/img/hero-bg-main.jpeg";
// import heroSecVid from "../../assets/hero-warp.mp4";
const Hero = () => {
  return (
    <section id="hero" className="hero section">
      {/* <img
          // src={
          //   "https://media1.giphy.com/media/v1.Y2lkPTc5MGI3NjExb3BhZjcxbXJjeWN1Y291ZTVmYXppbGx4YnVidXM5MHdpaWFqNGR4biZlcD12MV9pbnRlcm5hbF9naWZfYnlfaWQmY3Q9Zw/YnexM9LwlwGu4Z1QnS/giphy.gif"
          // }
          // src={
          //   "https://media2.giphy.com/media/v1.Y2lkPTc5MGI3NjExbjVna3EwcGlpMmFjcHVob3VtYmxiMW81cjBxNno5c2l4bWdubnF2ZiZlcD12MV9pbnRlcm5hbF9naWZfYnlfaWQmY3Q9Zw/ToMjGpyHdJiioVfdtK0/giphy.gif"
          // }
          // src={
          //   "https://media4.giphy.com/media/v1.Y2lkPTc5MGI3NjExYXJqNXkyZTgyeHc2Ym9pb2xvcWhtbTI4em50YnB0YWE1OXk1OXI1bCZlcD12MV9pbnRlcm5hbF9naWZfYnlfaWQmY3Q9Zw/ToMjGpPcTt3go0nrrFe/giphy.gif"
          // }
          src={heroSecGiphy}
          alt=""
          data-aos="fade-in"
        /> */}
      {/* <video
          className="hero-video"
          autoPlay
          loop
          muted
          playsInline
          preload="auto"
          data-aos="fade-in"
        >
          <source src={heroSecVid} type="video/mp4" />
          Your browser does not support the video tag.
        </video> */}
      <img src={heroSecBg} alt="" data-aos="fade-in" />

      <div className="container">
        <div className="row">
          <div className="col-lg-10">
            {/* <h2 data-aos="fade-up" data-aos-delay="100">
                Welcome to Our Website
              </h2> */}
            <h2
              // style={{ textTransform: "uppercase" }}
              data-aos="fade-up"
              data-aos-delay="100"
            >
              Unlock Data innovation
            </h2>
            <h2
              // style={{ textTransform: "uppercase" }}
              data-aos="fade-up"
              data-aos-delay="100"
            >
              through Mobitra’s AI
              {/* <span>Generative AI</span> */}
            </h2>
            <h2
              // style={{ textTransform: "uppercase" }}
              data-aos="fade-up"
              data-aos-delay="100"
            >
              platform
              {/* <span>Generative AI</span> */}
            </h2>
            <p data-aos="fade-up" data-aos-delay="200">
              Unlock the full potential of verified, accurate knowledge
              precisely when you need it the most.
            </p>
          </div>
          <div className="col-lg-5">
            {/* <div
              className="form-group mt-4"
              style={{ display: "inline-flex", gap: "10px", width: "100%" }}
            >
              <input
                type="email"
                className="form-control hero-input"
                placeholder="Email"
                style={{ width: "100%" }}
              />
              <button
                type="button"
                className="btn btn-primary"
                style={{ width: "50%" }}
              >
                Get Details
              </button>
            </div> */}
            {/* <form
                action="#"
                className="sign-up-form d-flex"
                data-aos="fade-up"
                data-aos-delay="300"
              >
                <input
                  type="email"
                  className="form-control"
                  placeholder="Enter email address"
                />
                <input
                  type="submit"
                  className="btn btn-primary"
                  value="Get Details"
                />
              </form> */}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Hero;
