import React, { useEffect } from "react";
import Hero from "./Hero";
import { useLocation } from "react-router-dom";
// import Conatct from "./Contact";
const PrivacyPolicy = () => {
  const location = useLocation();
  useEffect(() => {
    if (location.pathname === "/privacy-policy") {
      const element = document.getElementById("privacy-policy");
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [location.pathname]);
  return (
    <main className="main">
      <Hero />
      <section
        id="privacy-policy"
        className="services section"
        style={{ textAlign: "justify" }}
      >
        <div className="container section-title" data-aos="fade-up">
          <h2>Privacy Policy</h2>
          <p>PRIVACY NOTICE | Last updated June 01, 2022</p>
        </div>

        <div className="container">
          <div className="row gy-4">
            <div className="col-lg-12" data-aos="fade-up" data-aos-delay="100">
              <p>
                This privacy notice for MobiTra Technologies Private Limited
                ('Company', 'we', 'us', or 'our',), describes how and why we
                might collect, store, use, and/or share ('process') your
                information when you use our services ('Services'), such as when
                you:
              </p>
              <div>
                <ul>
                  <li>
                    Visit our website at mobitra.in, or any website of ours that
                    links to this privacy notice
                  </li>
                  <li>
                    Download and use our mobile application (MobiTra), or any
                    other application of ours that links to this privacy notice
                  </li>
                  <li>
                    Engage with us in other related ways, including any sales,
                    marketing, or events.
                  </li>
                </ul>
              </div>
              <p>
                Questions or concerns? Reading this privacy notice will help you
                understand your privacy rights and choices. If you do not agree
                with our policies and practices, please do not use our Services.
                If you still have any questions or concerns, please contact us
                at info@mobitra.in.
              </p>
              <h5>SUMMARY OF KEY POINTS</h5>
              <p>
                This summary provides key points from our privacy notice, but
                you can find out more details about any of these topics by
                clicking the link following each key point or by using our table
                of contents below to find the section you are looking for.
              </p>
              <p>
                What personal information do we process? When you visit, use, or
                navigate our Services, we may process personal information
                depending on how you interact with MobiTra Technologies Private
                Limited and the Services, the choices you make, and the products
                and features you use.
              </p>
              <p>
                Do we process any sensitive personal information? We do not
                process sensitive personal information.
              </p>{" "}
              <p>
                Do we receive any information from third parties? We do not
                receive any information from third parties.
              </p>
              <p>
                How do we process your information? We process your information
                to provide, improve, and administer our Services, communicate
                with you, for security and fraud prevention, and to comply with
                law. We may also process your information for other purposes
                with your consent. We process your information only when we have
                a valid legal reason to do so.
              </p>
              <p>
                In what situations and with which parties do we share personal
                information? We may share information in specific situations and
                with specific third parties.
              </p>
              <p>
                How do we keep your information safe? We have organisational and
                technical processes and procedures in place to protect your
                personal information. However, no electronic transmission over
                the internet or information storage technology can be guaranteed
                to be 100% secure, so we cannot promise or guarantee that
                hackers, cybercriminals, or other unauthorised third parties
                will not be able to defeat our security and improperly collect,
                access, steal, or modify your information.
              </p>
              <p>
                What are your rights? Depending on where you are located
                geographically, the applicable privacy law may mean you have
                certain rights regarding your personal information.
              </p>
              <p>
                How do you exercise your rights? The easiest way to exercise
                your rights by contacting us. We will consider and act upon any
                request in accordance with applicable data protection laws.
              </p>
              <h6>
                Want to learn more about what MobiTra Technologies Private
                Limited does with any information we collect?
              </h6>
              <div class="toc">
                <h5>Table of Contents</h5>
                <ol>
                  <li>
                    <a href="#section1"> WHAT INFORMATION DO WE COLLECT?</a>
                  </li>
                  <li>
                    <a href="#section2">HOW DO WE PROCESS YOUR INFORMATION?</a>
                  </li>
                  <li>
                    <a href="#section3">
                      WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?
                    </a>
                  </li>
                  <li>
                    <a href="#section4">
                      DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?
                    </a>
                  </li>
                  <li>
                    <a href="#section5">HOW DO WE HANDLE YOUR SOCIAL LOGINS?</a>
                  </li>
                  <li>
                    <a href="#section6">
                      HOW LONG DO WE KEEP YOUR INFORMATION?
                    </a>
                  </li>
                  <li>
                    <a href="#section7">
                      HOW DO WE KEEP YOUR INFORMATION SAFE?
                    </a>
                  </li>
                  <li>
                    <a href="#section8">
                      DO WE COLLECT INFORMATION FROM MINORS?
                    </a>
                  </li>
                  <li>
                    <a href="#section9"> WHAT ARE YOUR PRIVACY RIGHTS?</a>
                  </li>
                  <li>
                    <a href="#section10">CONTROLS FOR DO-NOT-TRACK FEATURES</a>
                  </li>
                  <li>
                    <a href="#section11">
                      DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?
                    </a>
                  </li>
                  <li>
                    <a href="#section12">DO WE MAKE UPDATES TO THIS NOTICE?</a>
                  </li>
                  <li>
                    <a href="#section13">
                      HOW CAN YOU CONTACT US ABOUT THIS NOTICE?
                    </a>
                  </li>
                  <li>
                    <a href="#section14">
                      HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT
                      FROM YOU?
                    </a>
                  </li>
                </ol>
              </div>
              <div id="section1">
                <h6>1. WHAT INFORMATION DO WE COLLECT?</h6>
                <p>
                  Personal information you disclose to us In Short: We collect
                  personal information that you provide to us.
                </p>
                <p>
                  We collect personal information that you voluntarily provide
                  to us when you register on the Services, express an interest
                  in obtaining information about us or our products and
                  Services, when you participate in activities on the Services,
                  or otherwise when you contact us.
                </p>
                <p>
                  Personal Information Provided by You. The personal information
                  that we collect depends on the context of your interactions
                  with us and the Services, the choices you make, and the
                  products and features you use.
                </p>
                <ul>
                  <li>names</li>
                  <li>phone numbers</li>
                  <li>email addresses</li>
                  <li>mailing addresses</li>
                  <li>usernames</li>
                  <li>passwords</li>
                  <li>contact preferences</li>
                  <li>contact or authentication data</li>
                  <li>billing addresses</li>
                  <li>debit/credit card numbers</li>
                  <li>job titles</li>
                </ul>
                <p>
                  Sensitive Information. We do not process sensitive
                  information.
                </p>
                <p>
                  Social Media Login Data. We may provide you with the option to
                  register with us using your existing social media account
                  details, like your Facebook, Twitter, or other social media
                  account. If you choose to register in this way, we will
                  collect the information described in the section called 'HOW
                  DO WE HANDLE YOUR SOCIAL LOGINS?' below.
                </p>
                <p>
                  Application Data. If you use our application(s), we also may
                  collect the following information if you choose to provide us
                  with access or permission:
                </p>
                <ul>
                  <li>
                    Geolocation Information. We may request access or permission
                    to track location-based information from your mobile device,
                    either continuously or while you are using our mobile
                    application(s), to provide certain location-based services.
                    If you wish to change our access or permissions, you may do
                    so in your device's settings.
                  </li>
                  <li>
                    Mobile Device Data. We automatically collect device
                    information (such as your mobile device ID, model, and
                    manufacturer), operating system, version information and
                    system configuration information, device and application
                    identification numbers, browser type and version, hardware
                    model Internet service provider and/or mobile carrier, and
                    Internet Protocol (IP) address (or proxy server). If you are
                    using our application(s), we may also collect information
                    about the phone network associated with your mobile device,
                    your mobile device’s operating system or platform, the type
                    of mobile device you use, your mobile device’s unique device
                    ID, and information about the features of our application(s)
                    you accessed.
                  </li>
                  <li>
                    Push Notifications. We may request to send you push
                    notifications regarding your account or certain features of
                    the application(s). If you wish to opt out from receiving
                    these types of communications, you may turn them off in your
                    device's settings.
                  </li>
                </ul>
                <p>
                  This information is primarily needed to maintain the security
                  and operation of our application(s), for troubleshooting, and
                  for our internal analytics and reporting purposes. All
                  personal information that you provide to us must be true,
                  complete, and accurate, and you must notify us of any changes
                  to such personal information. Information automatically
                  collected In Short: Some information — such as your Internet
                  Protocol (IP) address and/or browser and device
                  characteristics — is collected automatically when you visit
                  our Services.
                </p>
                <p>
                  We automatically collect certain information when you visit,
                  use, or navigate the Services. This information does not
                  reveal your specific identity (like your name or contact
                  information) but may include device and usage information,
                  such as your IP address, browser and device characteristics,
                  operating system, language preferences, referring URLs, device
                  name, country, location, information about how and when you
                  use our Services, and other technical information. This
                  information is primarily needed to maintain the security and
                  operation of our Services, and for our internal analytics and
                  reporting purposes. Like many businesses, we also collect
                  information through cookies and similar technologies. The
                  information we collect includes:
                </p>
                <ul>
                  <li>
                    Log and Usage Data. Log and usage data is service-related,
                    diagnostic, usage, and performance information our servers
                    automatically collect when you access or use our Services
                    and which we record in log files. Depending on how you
                    interact with us, this log data may include your IP address,
                    device information, browser type, and settings and
                    information about your activity in the Services (such as the
                    date/time stamps associated with your usage, pages and files
                    viewed, searches, and other actions you take such as which
                    features you use), device event information (such as system
                    activity, error reports (sometimes called 'crash dumps'),
                    and hardware settings).
                  </li>
                  <li>
                    Device Data. We collect device data such as information
                    about your computer, phone, tablet, or other device you use
                    to access the Services. Depending on the device used, this
                    device data may include information such as your IP address
                    (or proxy server), device and application identification
                    numbers, location, browser type, hardware model, Internet
                    service provider and/or mobile carrier, operating system,
                    and system configuration information.
                  </li>
                  <li>
                    Location Data. We collect location data such as information
                    about your device's location, which can be either precise or
                    imprecise. How much information we collect depends on the
                    type and settings of the device you use to access the
                    Services. For example, we may use GPS and other technologies
                    to collect geolocation data that tells us your current
                    location (based on your IP address). You can opt out of
                    allowing us to collect this information either by refusing
                    access to the information or by disabling your Location
                    setting on your device. However, if you choose to opt out,
                    you may not be able to use certain aspects of the Services.
                  </li>
                </ul>
              </div>
              <div id="section2">
                <h6>2. HOW DO WE PROCESS YOUR INFORMATION?</h6>
                <p>
                  In Short: We process your information to provide, improve, and
                  administer our Services, communicate with you, for security
                  and fraud prevention, and to comply with law. We may also
                  process your information for other purposes with your consent.
                  We process your personal information for a variety of reasons,
                  depending on how you interact with our Services, including:
                </p>
                <ul>
                  <li>
                    To facilitate account creation and authentication and
                    otherwise manage user accounts. We may process your
                    information so you can create and log in to your account, as
                    well as keep your account in working order.
                  </li>
                  <li>
                    To send administrative information to you. We may process
                    your information to send you details about our products and
                    services, changes to our terms and policies, and other
                    similar information.
                  </li>
                  <li>
                    To identify usage trends. We may process information about
                    how you use our Services to better understand how they are
                    being used so we can improve them.
                  </li>
                </ul>
              </div>
              <div id="section3">
                <h6>
                  3. WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?
                </h6>
                <p>
                  In Short: We may share information in specific situations
                  described in this section and/or with the following third
                  parties. We may need to share your personal information in the
                  following situations:
                </p>
                <ul>
                  <li>
                    Business Transfers. We may share or transfer your
                    information in connection with, or during negotiations of,
                    any merger, sale of company assets, financing, or
                    acquisition of all or a portion of our business to another
                    company.
                  </li>
                  <li>
                    When we use Google Maps Platform APIs. We may share your
                    information with certain Google Maps Platform APIs (e.g.
                    Google Maps API, Places API). To find out more about
                    Google’s Privacy Policy.
                  </li>
                </ul>
              </div>
              <div id="section4">
                <h6>4. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?</h6>
                <p>
                  In Short: We may use cookies and other tracking technologies
                  to collect and store your information. We may use cookies and
                  similar tracking technologies (like web beacons and pixels) to
                  access or store information. Specific information about how we
                  use such technologies and how you can refuse certain cookies
                  is set out in our Cookie Notice.
                </p>
              </div>
              <div id="section5">
                <h6>5. HOW DO WE HANDLE YOUR SOCIAL LOGINS?</h6>
                <p>
                  In Short: If you choose to register or log in to our services
                  using a social media account, we may have access to certain
                  information about you. Our Services offer you the ability to
                  register and log in using your third-party social media
                  account details (like your Facebook or Twitter logins). Where
                  you choose to do this, we will receive certain profile
                  information about you from your social media provider. The
                  profile information we receive may vary depending on the
                  social media provider concerned, but will often include your
                  name, email address, friends list, and profile picture, as
                  well as other information you choose to make public on such a
                  social media platform. We will use the information we receive
                  only for the purposes that are described in this privacy
                  notice or that are otherwise made clear to you on the relevant
                  Services. Please note that we do not control, and are not
                  responsible for, other uses of your personal information by
                  your third-party social media provider. We recommend that you
                  review their privacy notice to understand how they collect,
                  use, and share your personal information, and how you can set
                  your privacy preferences on their sites and apps.
                </p>
              </div>
              <div id="section6">
                <h6>6. HOW LONG DO WE KEEP YOUR INFORMATION?</h6>
                <p>
                  {" "}
                  In Short: We keep your information for as long as necessary to
                  fulfil the purposes outlined in this privacy notice unless
                  otherwise required by law. We will only keep your personal
                  information for as long as it is necessary for the purposes
                  set out in this privacy notice, unless a longer retention
                  period is required or permitted by law (such as tax,
                  accounting, or other legal requirements). No purpose in this
                  notice will require us keeping your personal information for
                  longer than the period of time in which users have an account
                  with us. When we have no ongoing legitimate business need to
                  process your personal information, we will either delete or
                  anonymise such information, or, if this is not possible (for
                  example, because your personal information has been stored in
                  backup archives), then we will securely store your personal
                  information and isolate it from any further processing until
                  deletion is possible.
                </p>
              </div>
              <div id="section7">
                <h6>7. HOW DO WE KEEP YOUR INFORMATION SAFE?</h6>
                <p>
                  {" "}
                  In Short: We aim to protect your personal information through
                  a system of organisational and technical security measures. We
                  have implemented appropriate and reasonable technical and
                  organisational security measures designed to protect the
                  security of any personal information we process. However,
                  despite our safeguards and efforts to secure your information,
                  no electronic transmission over the Internet or information
                  storage technology can be guaranteed to be 100% secure, so we
                  cannot promise or guarantee that hackers, cybercriminals, or
                  other unauthorised third parties will not be able to defeat
                  our security and improperly collect, access, steal, or modify
                  your information. Although we will do our best to protect your
                  personal information, transmission of personal information to
                  and from our Services is at your own risk. You should only
                  access the Services within a secure environment.
                </p>
              </div>
              <div id="section8">
                <h6>8. DO WE COLLECT INFORMATION FROM MINORS?</h6>
                <p>
                  In Short: We do not knowingly collect data from or market to
                  children under 18 years of age. We do not knowingly solicit
                  data from or market to children under 18 years of age. By
                  using the Services, you represent that you are at least 18 or
                  that you are the parent or guardian of such a minor and
                  consent to such minor dependent’s use of the Services. If we
                  learn that personal information from users less than 18 years
                  of age has been collected, we will deactivate the account and
                  take reasonable measures to promptly delete such data from our
                  records. If you become aware of any data we may have collected
                  from children under age 18, please contact us at
                  info@mobitra.in.
                </p>
              </div>
              <div id="section9">
                <h6>9. WHAT ARE YOUR PRIVACY RIGHTS?</h6>
                <p>
                  In Short: You may review, change, or terminate your account at
                  any time. If you are located in the EEA or UK and you believe
                  we are unlawfully processing your personal information, you
                  also have the right to complain to your local data protection
                  supervisory authority. You can find their contact details
                  here:
                  <a
                    href="https://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm."
                    target="_blank"
                    rel="noreferrer"
                  >
                    {" "}
                    https://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm.
                  </a>{" "}
                  If you are located in Switzerland, the contact details for the
                  data protection authorities are available here:
                  <a
                    href="https://www.edoeb.admin.ch/edoeb/en/home.html"
                    target="_blank"
                    rel="noreferrer"
                  >
                    {" "}
                    https://www.edoeb.admin.ch/edoeb/en/home.html
                  </a>
                  . Withdrawing your consent: If we are relying on your consent
                  to process your personal information, which may be express
                  and/or implied consent depending on the applicable law, you
                  have the right to withdraw your consent at any time. However,
                  please note that this will not affect the lawfulness of the
                  processing before its withdrawal, nor when applicable law
                  allows, will it affect the processing of your personal
                  information conducted in reliance on lawful processing grounds
                  other than consent. Account Information If you would at any
                  time like to review or change the information in your account
                  or terminate your account, you can:
                </p>
                <ul>
                  <li>
                    Log in to your account settings and update your user
                    account. Upon your request to terminate your account, we
                    will deactivate or delete your account and information from
                    our active databases. However, we may retain some
                    information in our files to prevent fraud, troubleshoot
                    problems, assist with any investigations, enforce our legal
                    terms and/or comply with applicable legal requirements.
                    Cookies and similar technologies: Most Web browsers are set
                    to accept cookies by default. If you prefer, you can usually
                    choose to set your browser to remove cookies and to reject
                    cookies. If you choose to remove cookies or reject cookies,
                    this could affect certain features or services of our
                    Services. If you have questions or comments about your
                    privacy rights, you may email us at{" "}
                    <strong>info@mobitra.in</strong>.
                  </li>
                </ul>
              </div>
              <div id="section10">
                <h6>10. CONTROLS FOR DO-NOT-TRACK FEATURES</h6>
                <p>
                  Most web browsers and some mobile operating systems and mobile
                  applications include a Do-Not-Track ('DNT') feature or setting
                  you can activate to signal your privacy preference not to have
                  data about your online browsing activities monitored and
                  collected. At this stage no uniform technology standard for
                  recognising and implementing DNT signals has been finalised.
                  As such, we do not currently respond to DNT browser signals or
                  any other mechanism that automatically communicates your
                  choice not to be tracked online. If a standard for online
                  tracking is adopted that we must follow in the future, we will
                  inform you about that practice in a revised version of this
                  privacy notice.
                </p>
              </div>
              <div id="section11">
                <h6>
                  11. DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?
                </h6>
                <p>
                  In Short: Yes, if you are a resident of California, you are
                  granted specific rights regarding access to your personal
                  information. California Civil Code Section 1798.83, also known
                  as the 'Shine The Light' law, permits our users who are
                  California residents to request and obtain from us, once a
                  year and free of charge, information about categories of
                  personal information (if any) we disclosed to third parties
                  for direct marketing purposes and the names and addresses of
                  all third parties with which we shared personal information in
                  the immediately preceding calendar year. If you are a
                  California resident and would like to make such a request,
                  please submit your request in writing to us using the contact
                  information provided below. If you are under 18 years of age,
                  reside in California, and have a registered account with
                  Services, you have the right to request removal of unwanted
                  data that you publicly post on the Services. To request
                  removal of such data, please contact us using the contact
                  information provided below and include the email address
                  associated with your account and a statement that you reside
                  in California. We will make sure the data is not publicly
                  displayed on the Services, but please be aware that the data
                  may not be completely or comprehensively removed from all our
                  systems (e.g. backups, etc.).
                </p>
              </div>
              <div id="section12">
                <h6>12. DO WE MAKE UPDATES TO THIS NOTICE?</h6>
                <p>
                  In Short: Yes, we will update this notice as necessary to stay
                  compliant with relevant laws. We may update this privacy
                  notice from time to time. The updated version will be
                  indicated by an updated 'Revised' date and the updated version
                  will be effective as soon as it is accessible. If we make
                  material changes to this privacy notice, we may notify you
                  either by prominently posting a notice of such changes or by
                  directly sending you a notification. We encourage you to
                  review this privacy notice frequently to be informed of how we
                  are protecting your information.
                </p>
              </div>
              <div id="section13">
                <h6>13. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</h6>
                <p>
                  If you have questions or comments about this notice, you may
                  email us at info@mobitra.in or by post to: Mobitra
                  Technologies Private Limited D-69, Sector 2 Noida, Uttar
                  Pradesh 201301 India.
                </p>
              </div>
              <div id="section14">
                <h6>
                  14. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT
                  FROM YOU?
                </h6>
                <p>
                  Based on the applicable laws of your country, you may have the
                  right to request access to the personal information we collect
                  from you, change that information, or delete it. To request to
                  review, update, or delete your personal information, please
                  submit a request to <strong>info@mobitra.in</strong>.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <Conatct /> */}
    </main>
  );
};

export default PrivacyPolicy;
