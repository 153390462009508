import React, { useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import Hero from "./Hero";
import aboutBg from "../../assets/img/about-bg.jpg";
import Solutions from "./Solutions";
import Team from "./Team";
const About = () => {
  const location = useLocation();
  useEffect(() => {
    if (location.pathname === "/about-us") {
      const element = document.getElementById("about");
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [location.pathname]);
  return (
    <main className="main">
      <Hero />
      <section id="about" className="pricing section">
        <div className="container section-title" data-aos="fade-up">
          <h2>About</h2>
          <p>
            Mobitra excels in creating industry-specific AI products,
            democratizing AI benefits through lean development. Our proprietary
            Data Management Framework powers generative AI solutions that
            revolutionize businesses.
          </p>
        </div>

        <div className="container" data-aos="zoom-in" data-aos-delay="100">
          <div className="row g-4">
            <div className="col-lg-12">
              <div className="text-center">
                <img
                  src={aboutBg}
                  className="img-fluid"
                  style={{ maxHeight: "600px" }}
                ></img>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="vision" className="team section">
        <div className="container section-title" data-aos="fade-up">
          <h2>Vision</h2>
          <p>
            With a vision to become the leading provider of cutting-edge AI
            solutions globally, Mobitra is committed to enabling businesses to
            leverage AI's transformative potential, optimizing operations,
            enhancing decision-making, and staying ahead in an increasingly
            competitive market.
          </p>
        </div>
      </section>
      <Solutions />
      <Team />
    </main>
  );
};

export default About;
