import React from "react";
import abhishekPic from "../../assets/img/Abhishek_pic.jpg";
const Team = () => {
  return (
    <section id="team" className="team section">
      <div className="container section-title" data-aos="fade-up">
        <h2>Leadership Team</h2>
        <p>
          The leadership team, a group of batch mates from IIT ISM Dhanbad, each
          with over two decades of global experience, has come together to
          revolutionize and transform businesses AI-wise. They have a proven
          track record of orchestrating end-to-end IT solutions across diverse
          industries such as Payments, Finance, Retail, the public sector, and
          consumer goods. Successfully integrating legacy data systems with
          cutting-edge generative AI technologies, they enhance data utilization
          and drive innovation. Their expertise has been instrumental in
          developing business for major corporations worldwide. This gang is
          here to lead the AI revolution, transforming industries and driving
          unprecedented growth.
          <br />
          The team is led by Abhishek Srivastava who is an engineering graduate
          from IIT ISM Dhanbad and MBA from HEC Paris.
        </p>
      </div>
      <div className="container">
        <div className="row gy-5 d-flex justify-content-center">
          <div
            className="col-lg-4 col-md-6 member"
            data-aos="fade-up"
            data-aos-delay="100"
          >
            <div className="member-img">
              <img src={abhishekPic} className="img-fluid" alt="" />
              {/* <div className="social">
                <a href="#">
                  <i className="bi bi-twitter-x"></i>
                </a>
                <a href="#">
                  <i className="bi bi-facebook"></i>
                </a>
                <a href="#">
                  <i className="bi bi-instagram"></i>
                </a>
                <a href="#">
                  <i className="bi bi-linkedin"></i>
                </a>
              </div> */}
            </div>
            <div className="member-info text-center">
              <h4>Mr. Abhishek Srivastava</h4>
              <span>Director</span>
              {/* <p>
                Engineering graduate from IIT ISM Dhanbad and MBA from HEC Paris
              </p> */}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Team;
