import React from "react";
import blog1 from "../../assets/img/blogs/blog1.jpeg";
import blog2 from "../../assets/img/blogs/blog2.jpeg";
import blog3 from "../../assets/img/blogs/blog3.jpeg";
// import blog4 from "../../assets/img/blogs/blog4.jpeg";
import blog5 from "../../assets/img/blogs/blog5.png";
const Blogs = () => {
  return (
    <section id="features" className="features section">
      {/* <div className="container section-title" data-aos="fade-up">
        <h2>Features</h2>
        <p>
          Necessitatibus eius consequatur ex aliquid fuga eum quidem sint
          consectetur velit
        </p>
      </div> */}

      <div className="container">
        {/* <div className="row gy-4 align-items-center features-item">
          <div
            className="col-lg-5 order-2 order-lg-1"
            data-aos="fade-up"
            data-aos-delay="200"
          >
            <h3>Generative AI Applications</h3>
            <p>
              Ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute
              irure dolor in reprehenderit in voluptate velit esse cillum dolore
              eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non
              proident.
            </p>
            <a href="#" className="btn btn-get-started">
              Get Started
            </a>
          </div>
          <div
            className="col-lg-7 order-1 order-lg-2 d-flex align-items-center"
            data-aos="zoom-out"
            data-aos-delay="100"
          >
            <div className="image-stack">
              <img src={featuresLight1} alt="" className="stack-front" />
              <img src={featuresLight2} alt="" className="stack-back" />
            </div>
          </div>
        </div> */}

        <div className="row gy-4 align-items-stretch justify-content-between features-item ">
          <div
            className="col-lg-5 d-flex justify-content-center flex-column"
            data-aos="fade-up"
          >
            <h1 className="blog-heads">Breaking Down silos</h1>
            <p>
              Our AI-driven adaptive search process enables teams to discover
              and summarize insights beyond their core expertise, fostering
              cross-functional innovation.
            </p>
            {/* <button className="btn learn-more-btn align-self-start mt-2">
              Learn more{" "}
              <span className="material-symbols-outlined">trending_flat</span>
            </button> */}
          </div>
          <div
            className="col-lg-6 d-flex align-items-center features-img-bg"
            data-aos="zoom-out"
          >
            <img src={blog1} className="img-fluid" alt="" />
          </div>
        </div>
        <div className="row gy-4 align-items-stretch justify-content-between features-item ">
          <div
            className="col-lg-6 d-flex align-items-center features-img-bg"
            data-aos="zoom-out"
          >
            <img src={blog2} className="img-fluid" alt="" />
          </div>
          <div
            className="col-lg-5 d-flex justify-content-center flex-column"
            data-aos="fade-up"
          >
            <h1 className="blog-heads">Enhancing Oversight</h1>
            <p>
              Our corporate governance tools empower management to identify and
              address gaps effectively, ensuring robust operational control.
            </p>
            {/* <button className="btn learn-more-btn align-self-start mt-2">
              Learn more{" "}
              <span className="material-symbols-outlined">trending_flat</span>
            </button> */}
          </div>
        </div>
        <div className="row gy-4 align-items-stretch justify-content-between features-item ">
          <div
            className="col-lg-5 d-flex justify-content-center flex-column"
            data-aos="fade-up"
          >
            <h1 className="blog-heads">Immediate Access to Relevant Data</h1>
            <p>Access your data at your convenience with Data on Demand.</p>

            {/* <button className="btn learn-more-btn align-self-start mt-2">
              Learn more{" "}
              <span className="material-symbols-outlined">trending_flat</span>
            </button> */}
          </div>
          <div
            className="col-lg-6 d-flex align-items-center features-img-bg"
            data-aos="zoom-out"
          >
            <img src={blog3} className="img-fluid" alt="" />
          </div>
        </div>
        <div className="row gy-4 align-items-stretch justify-content-between features-item ">
          <div
            className="col-lg-6 d-flex align-items-center features-img-bg"
            data-aos="zoom-out"
          >
            <img src={blog5} className="img-fluid" alt="" />
          </div>
          <div
            className="col-lg-5 d-flex justify-content-center flex-column"
            data-aos="fade-up"
          >
            <h1 className="blog-heads">Decision intelligence</h1>
            <p>
              Data-Driven Decisions: Empowers businesses with actionable
              insights derived from data.
            </p>
            <p>
              Efficiency and Scalability: Streamlines decision processes, making
              them faster and more scalable.
            </p>
            <p>
              Seamless Integration with Legacy Systems: Our DI product is
              designed to integrate effortlessly with existing enterprise
              systems (ERP, CRM, SCM, etc.). This ensures continuity and
              leverages existing investments.
            </p>
            <p>
              Real-Time Data Synchronization: Maintains up-to-date data flow
              between legacy systems and the DI platform, ensuring timely and
              accurate decision-making.
            </p>
            <p>
              Advanced Analytics and AI: <br />
              Predictive Analytics: Uses machine learning to forecast trends and
              outcomes.
            </p>
            <p>
              Prescriptive Analytics: Provides actionable recommendations to
              optimize decisions.
            </p>
            <p>
              Intuitive Dashboards: Visualize complex data in an
              easy-to-understand format.
            </p>
            <p>
              Customizable Views: Tailor the interface to meet the needs of
              different users and departments.
            </p>
            {/* <button className="btn learn-more-btn align-self-start mt-2">
              Learn more{" "}
              <span className="material-symbols-outlined">trending_flat</span>
            </button> */}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Blogs;
