import React, { useState } from "react";
import emailjs from "@emailjs/browser";
import { SERVICE_ID, TEMPLATE_ID, PUBLIC_KEY } from "../Common/main.js";

emailjs.init(PUBLIC_KEY);

const Contact = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    subject: "",
    message: "",
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [sentMessage, setSentMessage] = useState("");

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError("");
    setSentMessage("");
    const { name, email, subject, message } = formData;
    if (!name || !email || !subject || !message) {
      console.log("All fields are mandatory");
      return;
    }
    try {
      const result = await emailjs.send(SERVICE_ID, TEMPLATE_ID, formData);

      if (result.text === "OK") {
        setSentMessage("Your message has been sent. Thank you!");
        setError("");
      } else {
        setError("Failed to send the message. Please try again later.");
      }
    } catch (error) {
      setError("Failed to send the message. Please try again later.");
    } finally {
      setLoading(false);
      setTimeout(() => {
        setError("");
        setSentMessage("");
      }, 5000);
    }
  };
  return (
    <section id="contact" className="contact section">
      <div className="container section-title" data-aos="fade-up">
        <h2>CONTACT</h2>
        {/* <p>
          Necessitatibus eius consequatur ex aliquid fuga eum quidem sint
          consectetur velit
        </p> */}
      </div>

      <div className="container" data-aos="fade-up" data-aos-delay="100">
        <div className="row gy-4">
          <div className="col-lg-6">
            <div className="row gy-4">
              <div className="col-md-6">
                <div className="info-item" data-aos="fade" data-aos-delay="200">
                  <i className="bi bi-geo-alt"></i>
                  <h3>Locations:</h3>
                  <p>
                    {" "}
                    E-14, Surajpur Industrial Area, Site5, Noida, Gautam Budha
                    Nagar-201308, Uttar Pradesh, India
                  </p>
                  <br />
                  <p>
                    United Kingdom: Village Way, Pinner, United Kingdom,
                    Postcode: HA55AF
                  </p>
                  <br /> <br /> <br /> <br />
                </div>
              </div>

              <div className="col-md-6">
                <div className="info-item" data-aos="fade" data-aos-delay="200">
                  <i className="bi bi-telephone"></i>
                  <h3>Customer Care</h3>
                  <p>FleetGo:</p>
                  <p>+91 80811 75758</p>
                  <p>+91 91258 59377</p>
                </div>
                <div className="info-item" data-aos="fade" data-aos-delay="200">
                  <i className="bi bi-envelope"></i>
                  <h3>Email Us</h3>
                  <p>info@mobitra.in</p>
                </div>
              </div>

              {/* <div className="col-md-6">
                <div className="info-item" data-aos="fade" data-aos-delay="400">
                  <i className="bi bi-envelope"></i>
                  <h3>Email Us</h3>
                  <p>Info@mobitra.in</p>
                </div>
              </div> */}

              {/* <div className="col-md-6">
                <div className="info-item" data-aos="fade" data-aos-delay="500">
                  <i className="bi bi-clock"></i>
                  <h3>Open Hours</h3>
                  <p>Monday - Friday</p>
                  <p>9:00AM - 05:00PM</p>
                </div>
              </div> */}
            </div>
          </div>

          <div className="col-lg-6">
            <form
              className="php-email-form"
              data-aos="fade-up"
              data-aos-delay="200"
              onSubmit={handleSubmit}
            >
              <div className="row gy-4">
                <div className="col-md-6">
                  <input
                    type="text"
                    name="name"
                    className="form-control"
                    placeholder="Your Name"
                    value={formData.name}
                    onChange={handleChange}
                    required
                  />
                </div>

                <div className="col-md-6">
                  <input
                    type="email"
                    className="form-control"
                    name="email"
                    placeholder="Your Email"
                    value={formData.email}
                    onChange={handleChange}
                    required
                  />
                </div>

                <div className="col-md-12">
                  <input
                    type="text"
                    className="form-control"
                    name="subject"
                    placeholder="Subject"
                    value={formData.subject}
                    onChange={handleChange}
                    required
                  />
                </div>

                <div className="col-md-12">
                  <textarea
                    className="form-control"
                    name="message"
                    rows="6"
                    placeholder="Message"
                    value={formData.message}
                    onChange={handleChange}
                    required
                  ></textarea>
                </div>

                <div className="col-md-12 text-center">
                  {loading && <div className="d-block loading">Loading...</div>}
                  {error && (
                    <div className="d-block error-message">{error}</div>
                  )}
                  {sentMessage && (
                    <div className="d-block sent-message">{sentMessage}</div>
                  )}

                  <button
                    type="submit"
                    disabled={loading}
                    // disabled
                    // onClick={handleSubmit}
                  >
                    Send Message
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Contact;
