import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Body from "../Dashboard/Body";
// import Protected from "./Protected";

import NotFound from "../NotFound/NotFound";
import DashLayout from "./../Dashboard/DashLayout";
import PrivacyPolicy from "../Dashboard/PrivacyPolicy";
import SolutionsFleetGo from "../Dashboard/SolutionsFleetGo";
import SolutionsTaxGo from "../Dashboard/SolutionsTaxGo";
import About from "../Dashboard/About";

function WebRoutes() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/*" element={<NotFound />} />
        <Route
          path="/"
          element={
            <DashLayout>
              <Body />
            </DashLayout>
          }
        />
        <Route
          path="/privacy-policy"
          element={
            <DashLayout>
              <PrivacyPolicy />
            </DashLayout>
          }
        />
        <Route
          path="/solutions-fleet"
          element={
            <DashLayout>
              <SolutionsFleetGo />
            </DashLayout>
          }
        />
        <Route
          path="/solutions-tax"
          element={
            <DashLayout>
              <SolutionsTaxGo />
            </DashLayout>
          }
        />
        <Route
          path="/about-us"
          element={
            <DashLayout>
              <About />
            </DashLayout>
          }
        />
      </Routes>
    </BrowserRouter>
  );
}

export default WebRoutes;
