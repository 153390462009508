import React from "react";
import "./../../App.css";
import Header from "./Header";
import Footer from "./Footer";

function DashLayout({ children }) {
  return (
    <>
      <Header />
      {children}

      <Footer />
      <button
        style={{ border: "none" }}
        id="scroll-top"
        className="scroll-top d-flex align-items-center justify-content-center"
      >
        <i className="bi bi-arrow-up-short"></i>
      </button>

      {/* <div id="preloader">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div> */}
    </>
  );
}

export default DashLayout;
